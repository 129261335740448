.letterBox {
  height: 3rem;
  width: 3rem;
  border-radius: 10%;
  border: solid black 0.5px;
  padding: 0.5rem;
  text-align: center;
  line-height: 3rem;
  margin: 0.2rem 0.02rem;
  color: whitesmoke;
  font-weight: 600;
  font-size: 2.5rem;
  flex-shrink: 0;
  transition: background-color 0.5s ease;
}
.Win {
  animation-name: Bounce;
  animation-duration: 1000ms;
}

@keyframes Bounce {
  0%,
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}
