.GameOver {
  /* height: 50%;
  width: 65%; */
  background-color: rgb(54, 52, 104);
  border: 1px solid black;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: auto;
}
.GameOverParent {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
}
