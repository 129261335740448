body,
html,
#root {
  height: 100%;
  background-color: rgb(45, 43, 85);
}

h1 {
  font-size: 60px;
  padding: 0.77rem 0;
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: "Roboto", "sans-serif";
}

.App {
  height: 100%;
  font-family: "Roboto", "sans-serif";
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.signature {
  box-sizing: border-box;
  font-family: "Roboto", "sans-serif";
  padding: 10px;
  color: whitesmoke;
  text-align: center;
  margin: auto;
  width: 100%;
}
.filler {
  flex-grow: 1;
}
.icon {
  position: absolute;
  padding: 10px;
  cursor: pointer;
  width: 30px;
  top: 0;
  right: 0;
  border-radius: 13px;
}
.wrapper {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(
    124deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3
  );
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
