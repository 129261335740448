.Word {
  display: inline-flex;
  justify-content: center;
}
.Invalid {
  animation-name: Shake;
  animation-duration: 600ms;
}

@keyframes Shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}
